<template>
  <div class="about">
    <v-container>
     
          <h1><span class="metal">Principais Clientes</span><span class="shadow">Principais Clientes</span></h1>
          <v-item-group
          v-model="customer"
          class="pa-4 d-md-flex"
          active-class="--active"
        >
          <v-item
            v-for="n in customers"
            :key="n.name"
            v-slot="{ active, toggle }"
          >
            <v-card dark
            color="white"
              class="ma-2 pa-1 d-flex align-center"
             :width="['xs', 'sm'].includes($vuetify.breakpoint.name) ? '100%' : 240"
              @click="toggle"
            >
            <v-img :src="n.src" :width="['xs', 'sm'].includes($vuetify.breakpoint.name) ? '100%' : 240" :alt="n.name" contain max-height="140"></v-img>
            </v-card>
          </v-item>
        </v-item-group>
        <h1><span class="metal">Principais Fornecedores</span><span class="shadow">Principais Fornecedores</span></h1>
          <v-item-group
          v-model="provider"
          class="pa-4 d-md-flex"
          active-class="--active"
        >
          <v-item
            v-for="n in providers"
            :key="n.name"
            v-slot="{ active, toggle }"
          >
            <v-card dark
            color="white"
              class="ma-2 pa-1 d-flex align-center"
              :width="['xs', 'sm'].includes($vuetify.breakpoint.name) ? '100%' : 240"
              @click="toggle"
            >
            <v-img :src="n.src" :width="['xs', 'sm'].includes($vuetify.breakpoint.name) ? '100%' : 240" :alt="n.name" contain max-height="140"></v-img>
            </v-card>
          </v-item>
        </v-item-group>
        <v-dialog v-model="show" max-width="400">
          <v-card class="mx-auto pa-3">
            <v-card-title class="d-flex justify-space-between">{{ open ? open.name : '-' }}<v-btn @click="show = false" fab small><v-icon>mdi-close</v-icon></v-btn></v-card-title>
            <v-card-actions><v-btn class="accent" block :href="open ? open.url : ''" target="blank">Ir para página do {{ provider ? `fornecedor` : `cliente` }} <v-icon small class="ml-4">mdi-arrow-top-right</v-icon></v-btn></v-card-actions>
          </v-card>  
        </v-dialog>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    open: null,
    show: false,
    customer: null,
    provider: null,
    customers: [{
      name: "América Latina Refrigeração e Ar Condicionado",
      url: "http://www.americalatinarefrigeracao.com.br/",
      src: require('@/assets/logos/americalatina.jpg')
    },
    {
      name: "Gran Nobre Pisos Industriais",
      url: "http://www.grannobre.com.br/",
      src: require('@/assets/logos/grannobre.jpg')
    },
    {
      name: "Protendit",
      url: "https://www.protendit.com.br/",
      src: 'https://www.protendit.com.br/images/logo.gif' //require('@/assets/logos/esperadeancoragem.jpg')
    },
    {
      name: "Mitsuba",
      url: "http://www.mitsuba.com.br/",
      src: require('@/assets/logos/mitsuba.jpg')
    },{
      name: "Norte Buss",
      url: "http://nortebuss.com.br/",
      src: 'http://nortebuss.com.br/wp-content/uploads/2015/04/logo_norte_buss_oficial_colorido1.png'// require('@/assets/logos/transcooper.jpg')
    }

    
   ],
    providers: [
      {
      name: "HGL",
      url: "http://www.hglperfis.com.br/",
      src: require('@/assets/logos/hglperfis.jpg')
    },
    {
      name: "Liaços Ltda.",
      url: "http://www.liacosvalmar.com.br/",
      src: require('@/assets/logos/liacos.jpg')
    },
    {
      name: "Fergás",
      url: "https://www.fergasequipamentos.com.br/",
      src: 'https://www.fergasequipamentos.com.br/novo/log_fergas.png' //require('@/assets/logos/gasnorte.jpg')
    },
    {
      name: "DPC Metais",
      url: "https://dpcmetais.com.br/",
      src: 'https://dpcmetais.com.br/wp-content/uploads/2021/02/logo_site_dpc.png' //require('@/assets/logos/clligas.jpg')
    }
    ]
  }),
  watch: {
    customer(val){
      if(val !== null){
        this.open = this.customers[val]
        this.provider = null
      }
      
    },
    provider(val){
      if(val !== null){
        this.open = this.providers[val]
        this.customer = null
      }
    },
    open(val){
      this.show = val === null ? false : true
    },
    show(val){
      if(!val){
        this.open = null
        this.customer = null
        this.provider = null
      }

    }
  },


}
</script>