<template>
  <div class="services">
    <v-container>
      <h1><span class="metal">Serviços</span><span class="shadow">Serviços</span></h1>
      <v-slide-group v-model="model" class="pa-4" active-class="--active" show-arrows>
        <v-slide-item v-for="n in 7" :key="n" v-slot="{ active, toggle }">
          <v-card dark class="ma-2 pa-1" width="240" @click="toggle">
            <v-img :src="require(`@/assets/products/piece${n}.jpg`)" width="240"></v-img>
          </v-card>
        </v-slide-item>
      </v-slide-group>


      <ul :style="['xs', 'sm'].includes($vuetify.breakpoint.name) ? `` : `column-count: 2`" class="py-6">
        <li v-for="(item, index) in list" :key="index" class="metal" style="font-size: 22px">{{ item }}</li>
      </ul>



    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    model: null,
    list: [
      "Usinagem CNC e Convencional",
      "Manutenção Industrial",
      "Fabricação de Máquinas",
      "Confecção de Parafusos",
      "Fabricação de Engrenagens",
      "Soldas MIG e TIG",

      "Serviços de Torno CNC",
      "Serviços de Torno Mecânico",
      "Serviços de Plaina",
      "Serviços de Fresadora",
      "Serviços de Furadeira",
      "Retífica Plana"
    ]
  })
}
</script>
