<template>
  <v-app class="--app">
    <v-card tile color="#222" style="min-height: calc(100vh - 160px)">
      <v-app-bar app>
        <v-container class="d-flex align-center" style="position: relative" dark>
          <div style="position: absolute; right: 0; top: 0px; font-size: 12px;" class="px-8 accent--text">São Paulo, {{
              date
          }}</div>
          <div class="d-flex align-center">

            <v-img alt="Vuetify Name" class="shrink mt-1" contain min-width="100" src="@/assets/logo.png" width="100" />
          </div>

          <v-spacer></v-spacer>

          <v-btn to="/" text class="hidden-sm-and-down">
            Home
          </v-btn>
          <v-btn to="/a-empresa" text class="hidden-sm-and-down">
            A Empresa
          </v-btn>
          <v-btn to="/servicos" text class="hidden-sm-and-down">
            Serviços
          </v-btn>
          <v-btn to="/clientes-e-fornecedores" text class="hidden-sm-and-down">
            Clientes &amp; Fornecedores
          </v-btn>
          <v-btn to="/fale-conosco" text class="hidden-sm-and-down">
            Contato
          </v-btn>
          <v-btn @click="drawer = !drawer" class="hidden-md-and-up" icon color="primary"><v-icon>mdi-menu</v-icon></v-btn>
        </v-container>
      </v-app-bar>

      <v-main>
        <router-view />
      </v-main>
    </v-card>
    <v-navigation-drawer v-model="drawer" fixed right>
      <v-list-item to="/">
        <v-list-item-content>
          <v-list-item-title class="text-h6">
              Home
          </v-list-item-title>
         
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/a-empresa">
        <v-list-item-content>
          <v-list-item-title class="text-h6">
              A Empresa
          </v-list-item-title>
         
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/servicos">
        <v-list-item-content>
          <v-list-item-title class="text-h6">
             Serviços
          </v-list-item-title>
         
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/clientes-e-fornecedores">
        <v-list-item-content>
          <v-list-item-title class="text-h6">
             Clientes &amp; Fornecedores
          </v-list-item-title>
         
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/fale-conosco">
        <v-list-item-content>
          <v-list-item-title class="text-h6">
             Contato
          </v-list-item-title>
         
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>
    <v-footer>

      <v-container>
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <address>&copy; 1999 - {{ year }}. Todos os direitos reservados</address>
          </v-col>
          <v-col cols="12" md="6" sm="12" class="">
            <p>
              <v-icon color="accent">mdi-phone</v-icon> 55 11 2203-5690
            </p>
            <p>
              <v-icon color="accent">mdi-at</v-icon> planmaq@gmail.com
            </p>
            <p>
              <v-icon color="accent">mdi-home</v-icon> Av. Cel. Sezefredo Fagundes, 3618 - CEP 02306-004
            </p>

          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import moment from 'moment'
export default {
  name: 'App',

  data: () => ({
    drawer: false,
    //
    year: moment().format('YYYY')
  }),
  computed: {
    date() {
      return moment().locale('pt-br').format('LLLL')

    }
  }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@1,700&display=swap');

$red: #cd1222;

.--app {

  h1,
  h2 {
    position: relative;
    font-family: 'Roboto Condensed', sans-serif !important;
  }

  .metal {
    background: -webkit-linear-gradient(270deg, #fff 0%, #fff 25%, #ccc 49.9%, #999 50%, #333 80%);
    //  background: -webkit-linear-gradient(200deg, #fff 0%, #ccc 20%, #909090 50%, #999 100% );
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    z-index: 1;
    position: relative;
  }

  .metal-bg {
    background: -webkit-linear-gradient(270deg, #ccc, #222 50%) !important;
    //  background: -webkit-linear-gradient(200deg, #fff 0%, #ccc 20%, #909090 50%, #999 100% );
    position: relative;
    box-shadow:
      1px 3px 0 rgba(0, 0, 0, .3),
      0px 5px 11px rgba(0, 0, 0, .5),
      -1px -1px 0 #fff, -1px 0 0 #fff, 0 -1px 0 #fff !important;
  }

  .shadow {
    text-shadow:
      2px 2px 0 rgba(0, 0, 0, .5),
      1px 2px 5px rgba(0, 0, 0, .5),
      -1px -1px 0 #fff, -1px 0 0 #fff, 0 -1px 0 #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .v-toolbar,
  .v-toolbar__content {
    height: 100px !important;
    background: rgba(0, 0, 0, 0) !important;
  }

  .v-main {
    padding-top: 100px !important;
  }

  .v-toolbar {
    border-top: 2px solid $red;
    box-shadow: none !important;
  }

  .v-card {
    transition: .3s all;

    &.--active {
      background-color: $red;
    }
  }
}
</style>