<template>
  <div :style="` margin-top: -100px;min-height: min-height: calc(100vh - 160px)`">
    <video :src="videoCover" autoplay muted loop></video>

    <div
      style="width: 100%; height: 100%; background: rgba(0,0,0,.1); min-height: calc(100vh - 160px);  padding-top: 150px; position: relative; z-index: 1">

      <v-container>

        <h1 style="font-size: 46px" class="my-10">
          <span class="metal">Desde 1999 concluindo serviços com QUALIDADE e TECNOLOGIA, prestigiando cada cliente,
            sempre em busca da excelência em soluções de USINAGEM e SOLDA.</span>
          <span class="shadow">Desde 1999 concluindo serviços com QUALIDADE e TECNOLOGIA, prestigiando cada cliente,
            sempre em busca da excelência em soluções de USINAGEM e SOLDA.</span>
        </h1>

        <v-row class="pt-10">
          <v-col cols="12" md="3" sm="12">
            <v-card class="pa-3 metal-bg" to="/a-empresa">
              <h2 style="font-size: 26px">
                A Empresa
              </h2>
              <p>Veja quem somos, e alguns de nossos equipamentos</p>
            </v-card>

          </v-col>
          <v-col cols="12" md="3" sm="12">
            <v-card class="pa-3 metal-bg" to="/servicos">
              <h2 style="font-size: 26px">
                Serviços
              </h2>
              <p>Aqui você encontra os serviços que fazemos</p>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" sm="12">
            <v-card class="pa-3 metal-bg" to="/clientes-e-fornecedores">
              <h2 style="font-size: 26px">
                Clientes &amp; fornecedores
              </h2>
              <p>Conheça nossos principais clientes e fornecedores</p>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" sm="12">
            <v-card class="pa-3 metal-bg" to="/fale-conosco">
              <h2 style="font-size: 26px">
                Fale conosco
              </h2>
              <p>Entre em contato agora mesmo, e faça um orçamento!
              </p>
            </v-card>
          </v-col>
        </v-row>



      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',

  data: () => ({
    imgCover: require('@/assets/products/welding.gif'),
    videoCover: require('@/assets/products/welding3.mp4'),
    ecosystem: [
      {
        text: 'vuetify-loader',
        href: 'https://github.com/vuetifyjs/vuetify-loader',
      },
      {
        text: 'github',
        href: 'https://github.com/vuetifyjs/vuetify',
      },
      {
        text: 'awesome-vuetify',
        href: 'https://github.com/vuetifyjs/awesome-vuetify',
      },
    ],
    importantLinks: [
      {
        text: 'Documentation',
        href: 'https://vuetifyjs.com',
      },
      {
        text: 'Chat',
        href: 'https://community.vuetifyjs.com',
      },
      {
        text: 'Made with Vuetify',
        href: 'https://madewithvuejs.com/vuetify',
      },
      {
        text: 'Twitter',
        href: 'https://twitter.com/vuetifyjs',
      },
      {
        text: 'Articles',
        href: 'https://medium.com/vuetify',
      },
    ],
    whatsNext: [
      {
        text: 'Explore components',
        href: 'https://vuetifyjs.com/components/api-explorer',
      },
      {
        text: 'Select a layout',
        href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
      },
      {
        text: 'Frequently Asked Questions',
        href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
      },
    ],
  }),
}
</script>
<style>
video {
  height: 100%;
  min-height: calc(100vh - 160px);
  width: 100%;
  object-fit: cover;
  position: absolute;
}
</style>